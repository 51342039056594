import type { FC } from 'react';
import React, { useCallback, Fragment } from 'react';
import { useApolloClient, useMutation } from '@apollo/react-hooks';
import type { WrappedComponentProps } from 'react-intl';
import { defineMessages, injectIntl } from 'react-intl';

import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import type { ShortcutEvent } from '@confluence/shortcuts';
import { updateStarredSpacesList } from '@confluence/space-utils/entry-points/updateStarredSpacesList';

import { SpaceStarMutation, SpaceUnstarMutation } from '../ActionButtonMutations.graphql';
import { ActionErrorFlag } from '../SharedComponents';
import type { ButtonChildren } from '../PageStar/PageStar';
import { SpaceStarFragment } from '../ActionButtonFragments.fragment';

export type AnalyticsOptions = {
	source: string;
	containerId?: string;
	attributes?: Record<string, any>;
};

export type StarMutationArgs = {
	[attribute: string]: any;
};

type IconSpacing = 'none' | 'spacious';

export type SpaceStarProps = {
	spaceId: string;
	spaceKey: string;
	isStarred: boolean;
	onStar?: (e: MouseEvent | KeyboardEvent | ShortcutEvent) => void;
	onUnstar?: (e: MouseEvent | KeyboardEvent | ShortcutEvent) => void;
	analytics?: AnalyticsOptions;
	legacy?: boolean;
	spacing?: IconSpacing;
};

const i18n = defineMessages({
	starErrorTitle: {
		id: 'action-buttons.space.star.error.title',
		// TODO: replace straight quotes with curly quotes (see go/curlyquotes)
		// eslint-disable-next-line no-restricted-syntax
		defaultMessage: "We're having trouble starring this space",
		description:
			"Title of error flag shown when a user attempts to click the 'Star this space' button but the starring action fails",
	},
	unstarErrorTitle: {
		id: 'action-buttons.space.unstar.error.title',
		// TODO: replace straight quotes with curly quotes (see go/curlyquotes)
		// eslint-disable-next-line no-restricted-syntax
		defaultMessage: "We're having trouble unstarring this space",
		description:
			"Title of error flag shown when a user attempts to click the 'Unstar this space' button but the unstarring action fails",
	},
	errorDescription: {
		id: 'action-buttons.error.description',
		defaultMessage: 'Refresh the page and try again.',
		description:
			'Body of error flag shown when user attempts an action (e.g. star) but it fails. Shown below the error title.',
	},
});
const SpaceStarComponent: FC<SpaceStarProps & ButtonChildren & WrappedComponentProps> = ({
	spaceId,
	spaceKey,
	isStarred,
	onStar,
	onUnstar,
	analytics,
	intl,
	children,
}) => {
	const apolloClient = useApolloClient();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const isSpaceAliasFFEnabled = true;

	const modifyStarCache = useCallback(
		(newStarredValue: boolean) => {
			// The (Un)FavoriteSpaceMutations do not return the updated space, so we need to manually update the cache.
			// This is necessary to update the UI immediately after the user clicks the star button.
			apolloClient.writeFragment({
				id: `Space:${spaceId}`,
				fragment: SpaceStarFragment,
				data: {
					currentUser: {
						isFavourited: newStarredValue,
						__typename: 'SpaceUserMetadata',
					},
					__typename: 'Space',
				},
			});

			// Update the starred spaces list in the Spaces dropdown (and any other consumers of useSpacesData)
			updateStarredSpacesList(spaceId, newStarredValue, isSpaceAliasFFEnabled);
		},
		[spaceId, isSpaceAliasFFEnabled, apolloClient],
	);

	const [starSpace, { error: starError }] = useMutation<StarMutationArgs>(
		// eslint-disable-next-line graphql-relay-compat/no-import-graphql-operations -- Read https://go/connie-relay-migration-fyi
		SpaceStarMutation,
		{
			onError: () => {
				// Revert the cache back to the previous state
				modifyStarCache(false);
			},
		},
	);

	const [unstarSpace, { error: unstarError }] = useMutation<StarMutationArgs>(
		// eslint-disable-next-line graphql-relay-compat/no-import-graphql-operations -- Read https://go/connie-relay-migration-fyi
		SpaceUnstarMutation,
		{
			onError: () => {
				// Revert the cache back to the previous state
				modifyStarCache(true);
			},
		},
	);

	const toggleStar = useCallback(
		(e: any) => {
			e.preventDefault();
			if (isStarred) {
				onUnstar && onUnstar(e);
			} else {
				onStar && onStar(e);
			}

			// Optimistically update the cache
			modifyStarCache(!isStarred);

			const mutation = isStarred ? unstarSpace : starSpace;
			void mutation({
				variables: { spaceKey },
			});

			if (analytics) {
				const { source, containerId, attributes } = analytics;
				createAnalyticsEvent({
					type: 'sendTrackEvent',
					data: {
						action: isStarred ? 'unstarred' : 'starred',
						actionSubject: 'space',
						source,
						attributes,
						...(containerId && { containerId }),
					},
				}).fire();
			}
		},
		[
			isStarred,
			spaceKey,
			analytics,
			starSpace,
			unstarSpace,
			modifyStarCache,
			onStar,
			onUnstar,
			createAnalyticsEvent,
		],
	);

	return (
		<Fragment>
			{children({
				toggle: toggleStar,
			})}
			{starError && (
				<ActionErrorFlag
					title={intl.formatMessage(i18n.starErrorTitle)}
					description={intl.formatMessage(i18n.errorDescription)}
					error={starError}
				/>
			)}
			{unstarError && (
				<ActionErrorFlag
					title={intl.formatMessage(i18n.unstarErrorTitle)}
					description={intl.formatMessage(i18n.errorDescription)}
					error={unstarError}
				/>
			)}
		</Fragment>
	);
};

export const SpaceStar = injectIntl(SpaceStarComponent);
