import { styled, css } from '@compiled/react';

import { token } from '@atlaskit/tokens';
import { N0, N50, N500, B50, B400, N800 } from '@atlaskit/theme/colors';
import Button from '@atlaskit/button';

const buttonStyle = css({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	height: '32px',
	width: '32px',
	border: 'none',
	borderRadius: '3px',
	background: 'transparent',
	cursor: 'pointer',
	'&:hover': {
		background: token('color.background.neutral.hovered', 'rgba(9, 30, 66, 0.08)'),
	},
});

const menuButtonIconColor = (isWhiteboardBetaEnabled: boolean) => {
	return isWhiteboardBetaEnabled ? token('color.icon.selected', N0) : token('color.icon', N500);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const MenuButton = styled(Button)<{
	isOpen: boolean;
	isWhiteboardBetaEnabled: boolean;
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
}>(buttonStyle, {
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'&& svg': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		color: (props) =>
			props.isOpen
				? menuButtonIconColor(props.isWhiteboardBetaEnabled)
				: token('color.icon.subtle', N50),
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'&&:hover svg': {
		color: token('color.icon', N500),
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const DefaultLabel = styled.div({
	backgroundColor: token('color.background.neutral', 'rgba(9, 30, 66, 0.13)'),
	color: token('color.text', N500),
	borderRadius: '3px',
	font: token('font.body.small'),
	fontWeight: token('font.weight.bold'),
	padding: '1px 3px',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const NoIcon = styled.div({
	height: '24px',
	width: '24px',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const FlexCenter = styled.div({
	width: '100%',
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	flexGrow: 1,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const MoreButtonContainer = styled.div({
	margin: `${token('space.100', '8px')} 0 ${token('space.100', '8px')} 0px`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const CurrentViewPill = styled.button({
	backgroundColor: token('color.background.accent.blue.subtlest', B50),
	color: token('color.text.brand', B400),
	margin: '4px 4px 4px 8px',
	borderRadius: '100px',
	padding: `${token('space.050', '4px')} ${token('space.100', '8px')}`,
	border: 'none',
	font: token('font.body'),
	display: 'flex',
	alignItems: 'center',
	outline: 'none',
	cursor: 'pointer',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const ErrorContainer = styled.div({
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	justifyContent: 'center',
	textAlign: 'center',
	height: '180px',
	color: token('color.text', N800),
	padding: token('space.150', '12px'),
	margin: `${token('space.100', '8px')} 0 0 ${token('space.250', '20px')}`,
	font: token('font.body'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const ErrorIconWrapper = styled.div({
	marginBottom: token('space.100', '8px'),
});
